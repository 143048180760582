.EditorContainer {
    background-color: #fff;
    zoom: 1.4;
}
.ql-toolbar {
    background-color: white;
    display: flex;
    justify-content: center;
}
.ql-editor{
    overflow-y: none !important;
}
.ant-modal-mask {
    z-index: 9999 !important;
}
.ant-modal-wrap {
    z-index: 99999 !important;
}