.title-container{
    margin-top: 5px;
}

.title{
    height: 30px;
    width: fit-content;
    border: none;
    letter-spacing: 2px;
    font-family: Roboto, RobotoDraft, Helvetica, Arial, sans-serif;
    font-size: 18px;
}
.title:focus{
    outline:none;
}


@media screen and (max-width: 768px){
    .title{
        position: absolute;
        top: 15px;
        left: 55px;        
        margin-top: 5px;


    }
}