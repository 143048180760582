.Loader-editor-container{
    width: 210mm;
    min-height: 297mm;
    max-height: fit-content;
    margin: 0 auto;
    /* margin-top: 50px; */
    background-color: #F4F4F4;
    box-shadow: 0px 0px 5px 0.5px rgba(175,174,174,0.637); 
    /* padding: 25px; */
    /* overflow-y: none; */
    display: flex;
}




