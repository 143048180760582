.version-btn-container {
    display: flex;
    gap: 8px;
}

.version-button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36px;
    margin-top: 6px;
    width: fit-content;
    background-color: rgb(82, 107, 177);
    color: white;
    border-radius: 15px;
    font-size: 15px;
    font-weight: 500;
}

.version-button:hover {
    background-color: rgb(82, 107, 177);
    font-size: 15.5px;
    color: white;
}

.document-version-icon-container {
    width: 100%;
    cursor: pointer;
    margin-top: 6px;
    margin-left: 6px;
    /* margin-right: 6px; */
}

.document-version-icon {
    width: 28px;
}

.version-container {
    /* display: flex;
    flex-direction: column;
    height: 80px; */
}

.versionData-box {
    display: flex;
    flex-direction: column;
    cursor: pointer;
}

.version-createdAt {
    font-weight: 500;
    font-size: 18px;
    color: rgba(0, 0, 0, 0.777);
}

.version-creator {
    margin-top: 2px;
}

.creator-name {
    margin-left: 8px;
    font-weight: 400;
    font-size: 13px;
    color: rgba(12, 12, 12, 0.9);
}

.creator-image {
    width: 40px;
}

.creator-avatar {
    background-color: #fde3cf;
    color: #f56a00;
}
.create-version-btn{
    height: 28px;
    width: auto;
    font-size: 12px;
    border-radius: 12px;
    margin-bottom: 8px;
}
.section-divider{
    border-bottom: 0.5px solid #7d797966;
}
.no-versions-message {
    text-align: center;
    color: #888;
    margin-top: 20px;
    font-size: 14px;
}
