@media print {
    @page {
        margin: 0;
        padding: 0;
        size: A4;
    }

    body {
        margin: 0;
        padding: 0;
    }
    .page-break {
        page-break-after: always;
    }
}
