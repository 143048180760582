.main-editor-container {
  margin-top: 96px;
  position: relative;
  left: 290px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 25px;

}

.DraftEditor-root {
  font-size: 12px;
  height: 1062px;
  min-height: 1062px;
  max-height: 1062px;
  overflow-y: hidden;
}

.DraftEditor-editorContainer {
  line-height: 1.5;
}

.rdw-embedded-modal {
  height: auto !important;
}

.rdw-link-modal {
  height: auto !important;
}

.rdw-image-wrapper {
  display: none !important;
}

.rdw-editor-toolbar {
  /* margin-top: -6rem; */
  /* margin-left: -26px; */
  width: 98.6%;
  position: fixed;
  height: max-content;
  background-color: white;
  top: 65px;
  left: 25px;
  height: max-content;
  /* align-items:normal; */
  margin-left: -10px;
  z-index: 999;
}

.rdw-editor-toolbar button {
  width: 200px;
  height: 20px;
}

.rdw-editor-main {
  /* margin-top: 2rem; */
  /* min-height: 80vh; */
  overflow: hidden;
}

.rdw-option-wrapper {
  /* background-color: rgba(232, 230, 230, 0.956); */
}

.custom-toolbarOptions-Container {
  /* width: auto; */
  display: flex;
  justify-content: center;
  padding: 6px 0;
  /* align-items: center; */
}

.AI-icon {
  position: fixed;
  bottom: 90px;
  right: 48px;
  z-index: 10;
}

/* @media screen and (max-width: 768px) {
  .rdw-option-wrapper img{
    width:9px;
    
    }
}
@media screen and (max-width: 576px) {

.rdw-option-wrapper img{
width:9px;

} */
/* .rdw-editor-toolbar
{   padding: 0%;
    margin-top: -6rem;
    margin-left: -26px;
    display: flex;
    width: 375px;
    position: fixed;
    top: 48px;
    left: 15px;
    height: 45px;
    align-items: center;
    font-size: 10px;
    height: max-content;
    padding-right: 10px;
} */
/* .rdw-option-wrapper{
 width: 20px; 
 background-color: rgba(232, 230, 230, 0.956);
}
.rdw-block-wrapper{
  width: 60px;
  font-size: 8px;
}
.rdw-fontsize-wrapper{
  width:25px;
  font-size: 8px;
  margin: 0;
  padding: 0;
}
.rdw-fontfamily-wrapper{
  width: 80px;
  font-size: 8px;
  margin-left:0px ;
}
.rdw-colorpicker-wrapper{
  width: 35px;
}
} */
.RichEditor-style__line-height-1 {
  line-height: 1;
}

.RichEditor-style__line-height-1-15 {
  line-height: 1.15;
}

.RichEditor-style__line-height-1-5 {
  line-height: 1.5;
}

.RichEditor-style__line-height-2 {
  line-height: 2;
}

.RichEditor-style__align-left {
  text-align: left;
}

.RichEditor-style__align-center {
  text-align: center !important;
}

.RichEditor-style__align-right {
  text-align: right;
}

.RichEditor-style__align-justify {
  text-align: justify;
}

.public-DraftStyleDefault-ltr {
  text-align: inherit;
}
.rdw-text-align-wrapper {
  display: none;
}
.rdw-option-wrapper[title="Indent"],
.rdw-option-wrapper[title="Outdent"] {
    display: none;
}