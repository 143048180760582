.App{
    zoom: 80%;
}
.ql-editor::-webkit-scrollbar{
    display: none !important;
}
.summary-container{
    position: fixed;
    top: 166px;
    height: 100vh;
    overflow: hidden;
}
.ant-dropdown,
.ant-message,
.ant-tooltip,
.ant-select-dropdown,
.ant-modal {
  z-index: 9999 !important;
}