.editor {
    width: 210mm;
    height: 297mm;
    /* max-height: fit-content; */
    /* margin: 0 auto; */
    /* margin-top: 86px; */
    background-color: white;
    box-shadow: 0px 0px 5px 0.5px rgba(175,174,174,0.637);
    padding: 25px;
    overflow-y: none;
}
.ql-editor{
    overflow-y: none !important;
}
/* @media screen and (max-width: 550px) {
    .editor {
        width: 95mm;
        min-height: 210mm;
        max-height: fit-content;
        margin: 0 auto;
        margin-top: 85px;
        background-color: white;
        box-shadow: 0px 0px 5px 0.5px rgba(175,174,174,0.637);
        padding: 25px;
        overflow-y: none;
        margin-left: -70px;
    }  
} */

/* @media screen and (min-width: 551px) and (max-width: 768px) {
    .editor {
        width: 115mm;
    }
} */
/* @media screen and (min-width: 769px) and (max-width: 1000px) {
    .editor {
        width: 175mm;
        min-height: 210mm;
         max-height: fit-content; 
        margin: 0 auto;
        margin-top: 85px;
        background-color: white;
        box-shadow: 0px 0px 5px 0.5px rgba(175,174,174,0.637);
        padding: 25px;
        overflow-y: none;
        margin-left: -70px;
    }  
} */