.signature-container {
    position: absolute;
    z-index: 2;
    background-color: #fff;
    cursor: move;
    top: 1060px;
    left: 620px;
    margin-bottom: 5px;
}

.signature-box {
    border-bottom: 1px solid silver;
    text-align: center;
    margin: 5px;
    width: 160px;
    /* height: 65px; */
}

.name-label {
    text-align: center;
    /* width: 100%; */
    /* margin-left: 4px; */
    /* word-wrap: break-word; 
    display: inline-block;  */
}
.signature-image{
    width: 55px;
}