p,h4{
    margin-bottom: 0;
}
.ownerInfo{
    margin: 10px 0px ;
    padding: 5px;
    display: flex;
    align-items: flex-start;
}
.ownerInfo:hover{
    background-color:rgba(221, 219, 219, 0.685);
    border-radius: 30px 0px 0px 30px;
}
.info{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-around;
}

.avatar{
    margin:0px 10px;
}
.badge{
    margin-right: 10px;
}
@media screen and (max-width: 480px) {
    p{
        font-size: 8px;
    }
}


