.headerMain {
    display: flex;
    align-items: center;
    flex-wrap:nowrap;
    padding: 0 20px;
    margin-top: 8px;
    /* margin-bottom: 10px;
    background-color: white;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000; */
}

.headerCol {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
    height: 60px;
    width: 5px;
    margin-left: 20px;
}
.titleBarButtons{
    /* width: 180px; */
    /* margin-top: 20px; */
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.SavingSaved {
    display: flex;
    width: auto;
    margin-left: 8px;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

/* @media screen and (max-width: 1200px) {
    .headerCol{
        align-items: center;
    }    
    .titleBarButtons{
        width: fit-content;
        gap: 20px;
    }
    .headerMain {
        display: flex;
        align-items: center;
        flex-wrap:nowrap;
        padding: 0 20px;
        width: 480px;
        gap: 90%;
    }
} */

