.summary {
    font-size: 23px;
    text-transform: uppercase;
    font-weight: 400;
    margin-top: 40;
    width: fit-content;
    min-width: '200px';
    padding-left: '10px';
}

.main-content-container {
    padding: 18px;
    width: 300px;
}

.summary-content-container {
    margin-bottom: 10px;
}

.summary-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
}

.summary-actions {
    display: flex;
    gap: 10px;
}

.ai-icon-button {
    width: 18px;
    margin-top: -6px;
}

.textarea-editable {
    resize: 'none';
    height: max-content;
    overflow: hidden;
}

.textarea-readonly {
    resize: none;
    height: max-content;
    overflow: hidden;
    pointer-events: none;
}

/* Main container */
.main-content-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding: 20px;
    background-color: white;
    display: flex;
    flex-direction: column;
  }
  
  /* Wrapper for content sections */
  .content-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
  }
  
  /* Summary section */
  .summary-section {
    flex-shrink: 0; 
    margin-bottom: 10px;
  }
  
  /* Document name styling */
  .document-name {
    font-size: 22px;
    font-weight: 500;
  }
  
  /* Outline section */
  .headers-container {
    flex-grow: 1;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    min-height: 200px;
  }
  
  .outline-title {
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 400;
    margin-bottom: 6px;
  }
  
  /* Headings container with scroll */
  .headings-container {
    flex-grow: 1; 
    overflow-y: auto; 
    padding-right: 10px; 
  }
  
  .heading-item {
    margin-top: 4px;
    cursor: pointer;
    width: 200px;
    word-wrap: break-word;
  }
  
  /* No headings message */
  .no-headings-message {
    font-size: 18px;
    font-weight: 300;
    font-style: italic;
    width: 240px;
  }
  .divider-conatiner{
    width: 100%;
    margin-top: -25px;
  }