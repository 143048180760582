.navigationmain {
    display: flex;
    font-size: 15px;
    font-family: Roboto,RobotoDraft,Helvetica,Arial,sans-serif;
    color: #202124;
    align-items: baseline;
    zoom: 1.1;
    top: 25px;
    padding-top: 14px;
    position: absolute;
}


@media screen and (max-width: 480px){
    .navigationmain{
        display: flex;
        font-size: 13.2px;

    }
}
@media screen and (max-width: 768px){
    .navigationmain{
        display: flex;
        left: 50px;
        font-size: 13.2px;

    }
}