.editor-signature-container {
    position: absolute;
    z-index: 2;
    background-color: #fff;
    top: 1060px;
    left: 620px;
    margin-bottom: 5px;
    text-align: center;
}

.editor-signature-box {
    position: relative;
    margin: 5px;
    width: 160px;
    display: flex;
    flex-direction: column;
    /* padding-bottom: 20px;  */
}

.custom-border {
    display: flex;
    align-items: center;
    height: 2px;
    background-color: transparent;
}

.border-part {
    flex-grow: 1;
    background-color: rgba(0, 0, 0, 0.7);
    height: 1.64px;
    width: 12px;
}

.signature-id {
    padding: 0 2px;
    /* background-color: #fff;  */
    color: #1a1a1acf;
    font-size: 10.5px;
}

.editor-user-detail {
    text-align: center;
    display: flex;
    flex-direction: column;
    margin-top: 8px;
}

.editor-signature-image {
    width: 65px;
    margin-bottom: 4px;
}

.designation-style {
    font-size: 12px;
    margin-top: -2px;
}

.date-time-style {
    font-size: 10px;
    margin-bottom: 1px;
}

.placeSig-btn {
    border: 1px solid rgba(0, 0, 0, 0.268);
    width: auto;
    background-color: rgb(82, 107, 177);
    color: white;
    margin-bottom: 4px;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    font-size: 12px;
    cursor: pointer;
    padding: 0 8px;
}

.placeSig-btn:hover:active {
    background-color: rgb(68, 107, 213);
}